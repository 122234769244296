import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import { Container } from 'components/Container';
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';
import linkResolver from 'core/linkResolver';

import Home from './home';
import Artist from './artist';

const Page404 = ({ data }) => {
  if (!data?.prismicPage404?.data) return <h1>Page not found</h1>;

  return (
    <Module>
      <SEO {...data.prismicPage404.data} />
      <Container>Page 404</Container>
    </Module>
  );
};

export default withPrismicUnpublishedPreview(Page404, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      home: Home,
      artist: Artist,
    }),
  },
]);

const Module = styled.div``;

export const pageQuery = graphql`
  query Page404Query($uid: String!, $lang: String!) {
    prismic404(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
      }
    }
  }
`;
